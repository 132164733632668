<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑客户"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="客户名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入客户名称' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`所在城市`">
          <a-spin :spinning="loadingRegionOption">
            <a-cascader
              :field-names="{ label: 'name', value: 'id', children: 'cities' }"
              :options="regionOptions"
              :show-search="{ regionFilter }"
              placeholder="请选择所在城市"
              v-decorator="['region']"
            />
          </a-spin>
        </a-form-item>

        <a-form-item :label="`行业`">
          <a-select
            mode="multiple"
            placeholder="请选择所在行业"
            v-decorator="['industry_type_ids']"
            allow-clear
          >
            <a-select-option
              v-for="option in industryTypeOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="`设备`">
          <a-select
            mode="multiple"
            placeholder="请选择使用设备"
            v-decorator="['device_type_ids']"
            allow-clear
          >
            <a-select-option
              v-for="option in deviceTypeOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="登录账号">
          <a-input
            v-decorator="['users.username', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入登录账号' },
                { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="登录密码">
          <a-input-password
            autocomplete="new-password"
            v-decorator="['users.password', {
              rules: [
                { min: 6, message: '最少6个字符' },
                { max: 50, message: '最多50个字符' },
                { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="联系对象">
          <a-input
            v-decorator="['users.full_name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入联系对象' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="手机号码">
          <a-input
            v-decorator="['users.phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入手机号码' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="联系邮箱">
          <a-input
            v-decorator="['users.email', {
              normalize: this.$lodash.trim,
              rules: [
                { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="责任销售">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :loading="loadingSaleOption"
            v-decorator="['sale_id', {
              rules: [{ required: true, message: '请选择责任销售' }]
            }]"
          >
            <a-select-option
              v-for="user in salesOptions"
              :key="user.id"
              :value="user.id"
              :disabled="!user.track_registered_account"
            >
              {{ user.full_name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="签约主体">
          <a-select
            :loading="loadingContractingPartyOptions"
            show-search
            :filter-option="filterContractingPartyOption"
            v-decorator="[
              'contracting_party_id',
            ]"
            placeholder="请选择签约主体"
          >
            <a-select-option
              v-for="contracting_party in contractingPartyOptions"
              :key="contracting_party.id"
              :value="contracting_party.id"
              :disabled="!contracting_party.effective"
            >
              {{ contracting_party.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findAgentForm, updateAgent } from '@/api/agent'
import { findRegionWithoutDistrict } from '@/api/region'
import { findIndustryTypeOptions } from '@/api/industry_type'
import { findDeviceTypeOptions } from '@/api/device_type'
import { findEffectiveSaleOptions } from '@/api/user'
import { findContractingPartyOptions } from '@/api/contracting_party'

export default {
  name: 'EditAgent',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'agent' }),
      submitting: false,
      regionOptions: [],
      industryTypeOptions: [],
      deviceTypeOptions: [],
      salesOptions: [],
      loadingRegionOption: true,
      loadingIndustryTypeOption: true,
      loadingDeviceTypeOption: true,
      loadingSaleOption: true,
      contractingPartyOptions: [],
      loadingContractingPartyOptions: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
    this.fetchRegionOptions()
    this.fetchIndustryOptions()
    this.fetchDeviceOptions()
    this.fetchSalesOptions()
    this.fetchContractingPartiesOption()
  },
  methods: {
    fetchFormData() {
      findAgentForm(this.id).then((res) => {
        // 如果销售不存在，表单不设置销售值
        if (res.data.sale_id === 0) {
          delete res.data.sale_id
        }
        // 如果签约主体不存在，表单不设置签约主体
        if (res.data.contracting_party_id === 0) {
          delete res.data.contracting_party_id
        }
        this.form.setFieldsValue(res.data)
      })
    },

    // 加载所在城市选项
    fetchRegionOptions() {
      this.loadingRegionOption = true
      findRegionWithoutDistrict().then((res) => {
        if (res.code === 0) {
          this.regionOptions = res.data
        }
        this.loadingRegionOption = false
      })
    },

    // 加载行业选项
    fetchIndustryOptions() {
      this.loadingIndustryTypeOption = true
      findIndustryTypeOptions().then((res) => {
        if (res.code === 0) {
          this.industryTypeOptions = res.data
        }
        this.loadingIndustryTypeOption = false
      })
    },

    // 加载设备选项
    fetchDeviceOptions() {
      this.loadingDeviceTypeOption = true
      findDeviceTypeOptions().then((res) => {
        if (res.code === 0) {
          this.deviceTypeOptions = res.data
        }
        this.loadingDeviceTypeOption = false
      })
    },

    regionFilter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },

    // 加载责任销售选项
    fetchSalesOptions() {
      this.loadingSaleOption = true
      findEffectiveSaleOptions().then((res) => {
        this.salesOptions = res.data
        this.loadingSaleOption = false
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateAgent(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },

    filterContractingPartyOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    fetchContractingPartiesOption() {
      this.loadingContractingPartyOptions = true
      findContractingPartyOptions().then((res) => {
        if (res.code === 0) {
          this.contractingPartyOptions = res.data
        }
        this.loadingContractingPartyOptions = false
      })
    }
  }
}
</script>

import request from '@/utils/request'

// 查询设备类型列表数据
export function findDeviceTypes(params) {
  return request({
    url: `/device_types`,
    method: 'get',
    params: params
  })
}

// 查询单个设备类型表单值
export function findDeviceTypeForm(id) {
  return request({
    url: `/device_types/${id}/form`,
    method: 'get'
  })
}

// 更新设备类型数据
export function updateDeviceType(id, data) {
  return request({
    url: `/device_types/${id}`,
    method: 'put',
    data
  })
}

// 创建设备类型数据
export function createDeviceType(data) {
  return request({
    url: `/device_types`,
    method: 'post',
    data
  })
}

// 更新设备有效性
export function batchUpdateDeviceTypesEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/device_types/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询所有设备选项数据
export function findDeviceTypeOptions() {
  return request({
    url: `/device_types/options`,
    method: 'get'
  })
}
